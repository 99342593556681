<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>內容管理中心</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'FormList' }">表單列表</b-breadcrumb-item>
            <b-breadcrumb-item active>填寫內容</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-3 font-weight-bold">回答明細 - {{ form.title }}</h4>

      <b-overlay rounded="sm">
        <b-tabs v-model="activeTabIndex" @input="handleUserChangesTab">
          <template v-if="form.id">
            <template v-for="(tab, index) in tabs">
              <b-tab :key="index" :title="tab.title" v-if="tab.visible">
                <keep-alive>
                  <component
                    :is="tab.component"
                    ref="child"
                    :key="tab.href"
                    :active="activeTabIndex === index"
                    @done="handleTabContentLoaded"
                  />
                </keep-alive>
              </b-tab>
            </template>
          </template>
        </b-tabs>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import formApi from "@/apis/form";
import FormResultListEventRegistrations from "./FormResultListEventRegistrations";
import FormResultListQuestionnaireRegistrants from "./FormResultListQuestionnaireRegistrants";
import FormResultListQuestionnaireSubmissions from "./FormResultListQuestionnaireSubmissions";

export default {
  components: {
    FormResultListEventRegistrations,
    FormResultListQuestionnaireRegistrants,
    FormResultListQuestionnaireSubmissions,
  },
  data() {
    return {
      activeTabIndex: 0,
      initialized: false,
      form: {
        type: null,
      },
    };
  },
  async created() {
    await this.fetchForm();
    this.updateActiveTab();
    this.updateRouteHash();
  },
  computed: {
    tabs() {
      return [
        {
          title: "人員名單",
          href: "#questionnaire-registrants",
          visible: true,
          component: FormResultListQuestionnaireRegistrants,
        },
        {
          title: "填寫內容",
          href: "#questionnaire-submissions",
          visible: true,
          component: FormResultListQuestionnaireSubmissions,
        },
        {
          title: "報名資料",
          href: "#event-registration",
          visible: this.form.type === 'event',
          component: FormResultListEventRegistrations,
        },
      ];
    },
  },
  methods: {
    updateRouteHash() {
      const newHash = this.tabs[this.activeTabIndex]?.href;
      if (!newHash) return;

      this.$router.replace({
        path: this.$route.path,
        hash: newHash,
      }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    handleTabContentLoaded() {
      this.initialized = true;
    },
    updateActiveTab() {
      const matchedIndex = this.tabs.findIndex(tab => tab.href === this.$route.hash);
      this.activeTabIndex = matchedIndex >= 0 ? matchedIndex : 0;
    },
    handleUserChangesTab() {
      if (!this.initialized) return;
      this.updateRouteHash();
    },
    async fetchForm() {
      try {
        const response = await formApi.getForm(this.$route.params.id);
        this.form = response.data.data;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
      }
    },
  },
};
</script>
